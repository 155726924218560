









































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapState, mapGetters } from "vuex";
import cookies from "js-cookie";

import {
  Header,
  Footer,
  FilterSearch,
  FilterInfoString,
  ModalWithFields,
} from "@/components";
import {
  Button,
  Table,
  Thead,
  Tbody,
  TableRow,
  Pager,
  IconBase
} from "@/components/UI";
import PageLayout from "@/components/layouts/PageLayout.vue";
import Select from "@/components/UI/form/Select.vue";
import Plus from "@/components/UI/icon/Plus.vue";
import IconDownload from "@/components/UI/icon/Download.vue";
import { MIN_CONTAINER_WIDTH } from "@/constants";
import ROLES from "@/config/roles";
import { PAGE_SHORT_NAMES } from '@/config/pages';
import TABLE_COLUMNS from '@/config/table-columns';

@Component({
  computed: {
    ...mapState("applicationListState", [
      "sizePage",
      "activePage",
      "totalPages",
      "isLoaded",
      "isArchive",
    ]),
    ...mapGetters("applicationListState", ["getApplicationList", "getPageName", "getAllowCreateAppFromXml"]),
    ...mapGetters("tableState", ["getColumnTitles"]),
    ...mapGetters("authorizationState", ["getRole", "isManagerPartner", "isChiefManager"]),
    ...mapGetters("filterState", ["getFilterInfoString", "isActiveFilter"]),
  },
  methods: {
    ...mapActions("applicationListState", [
      "getInitialApplication",
      "changePage",
      "changeSizePage",
      "setArchive",
      "setActivePage",
      "getReport",
      "uploadXmlWithApps",
    ]),
    ...mapActions("application", ["fetchApplication", "cloneApplication"]),
    ...mapActions("tableState", ["setTableContentType"]),
    ...mapActions("filterState", ["parseFilterFromLocaleStorage"]),
  },
  components: {
    Button,
    Tbody,
    Thead,
    Table,
    TableRow,
    Select,
    Pager,
    PageLayout,
    Header,
    Footer,
    IconBase,
    Plus,
    IconDownload,
    FilterInfoString,
    FilterSearch,
    ModalWithFields,
  }
})
export default class Home extends Vue {
  public getInitialApplication!: () => void;
  public setArchive!: (val: boolean) => void;
  public setActivePage!: (num: number) => void;
  public getReport!: () => void;
  public setTableContentType!: (contentType: PAGE_SHORT_NAMES) => void;
  public parseFilterFromLocaleStorage!: (pageName: string) => void;
  public getRole!: ROLES;
  public getPageName!: string;
  public uploadXmlWithApps!: ({
    file,
    fileName,
  }: {
    file: File;
    fileName: string;
  }) => Promise<{
    succeedOrderIds: number[];
    notValidNumbers: string[];
    failedNumbers: string[];
  }>;
  public uploadXmlWithAppsResult: {
    succeedOrderIds: number[];
    notValidNumbers: string[];
    failedNumbers: string[];
  } | null = null;

  get savePagination() {
    return this.$route.params.savePagination || false;
  }

  public initialApplication() {
    if (this.$router.currentRoute.name === "archive") {
      this.setArchive(true);
    } else {
      this.setArchive(false);
    }

    this.parseFilterFromLocaleStorage(this.getPageName);
    this.getInitialApplication();
  }

  public beforeMount() {
    this.setTableContentType(PAGE_SHORT_NAMES.app);
  }

  get getDefaultContainerWidth() {
    return window.localStorage.getItem(`app-container-width`) ||
    (MIN_CONTAINER_WIDTH + "px");
  }

  get getDefaultCellsWidth() {
    const columns = TABLE_COLUMNS[PAGE_SHORT_NAMES.app][this.getRole] ?? TABLE_COLUMNS[PAGE_SHORT_NAMES.app][ROLES.Manager];

    return columns!.map((column, index) => (
      window.localStorage.getItem(`app-column-${index}-width`) ||
      column.part * 100 + "%"
    ))
  }

  public async uploadXmlWithAppsHandler({
    productId, // пока не требуется, тариф захардкожен на бэке
    identificationKind, // пока не требуется, способ идентификации захардкожен на бэке
    xmlFile,
  }: {
    productId: number;
    identificationKind: number;
    xmlFile: Array<File>;
  }) {
    const result = await this.uploadXmlWithApps({
      file: xmlFile[0],
      fileName: xmlFile[0].name,
    });

    this.uploadXmlWithAppsResult = result;
  }

  public reloadPageHandler() {
    window.location.reload();
  }

  public mounted() {
    this.initialApplication();
  }

  @Watch("$route")
  public beforeRouteUpdate(to: any, from: any, next: any) {
    this.initialApplication();
  }

  @Watch("uploadXmlWithAppsResult")
  public uploadXmlWithAppsResultWatcher() {
    if (this.uploadXmlWithAppsResult) {
      this.$modal.show("upload-xml-with-apps-result-modal");
    }
  }
}
